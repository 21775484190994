@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {


  @font-face {
    font-family: 'Graphik';
    src: local('Graphik'), local('Graphik'),
      url('../assets/Graphik/GraphikRegular.otf') format('otf'),
      url('../assets/Graphik/GraphikMedium.otf') format('otf'),
      url('../assets/Graphik/GraphikSemibold.otf') format('otf'),
      url('../assets/Graphik/GraphikBold.otf') format('otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }


}

html{
  // font-size: 62.5%;
}

.disabled  {
  @apply cursor-none pointer-events-none;
}


.btn {
  @apply font-bold py-16 px-4;

  &.btn-primary {
    @apply bg-primary text-white font-medium transition-all;

    &:hover {
      @apply bg-opacity-80;
    }
  }

  &.btn-grey {
    @apply bg-grey500 text-primary font-medium transition-all;

    &:hover {
      @apply bg-opacity-80;
    }
  }

  &.btn-disabled  {
    @apply cursor-none pointer-events-none bg-opacity-70;
  }

  &.btn-bordered {
    @apply bg-transparent text-[#667085] rounded-8 border-1 px-16 py-8 font-medium transition-all;

    &:hover {
      @apply  bg-[#667085] text-white ;
    }
  }
}

.auth-form-group {
  @apply flex flex-col mb-18;

  label {
    @apply font-graphik text-dark100 text-12 mb-2 font-normal;
  }

  input {
    @apply bg-grey100 outline-none text-dark200 text-sm font-normal w-full p-20 focus:ring-0 block placeholder-black placeholder-opacity-50;

    &.disabled {
      @apply pointer-events-none;
    }
  }

  .error {
    @apply text-red-400 font-graphik text-12 mt-4;
  }
}

.form {
  @apply flex justify-between flex-wrap;

  .form-row-half {
    @apply flex flex-col basis-full md:basis-[48%] mb-2;
  }

  .form-row-full {
    @apply flex flex-col basis-[100%] mb-2;
  }
}

.select--container {
  @apply inline-block relative w-full;

  select {
    @apply block appearance-none w-full bg-[#F0F3F5] border-none outline-none text-black text-opacity-70 text-base font-normal rounded-[4px] leading-tight focus:outline-none p-4;
  }

  .icon {
    @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-grey200;
  }
}

.password__container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  @apply bg-grey100;
}

.password__container button {
  position: absolute;
  right: 5%;
  top: 20%;
  transform: translateY(30%);
  outline: none;
  border: 0;
  background-color:transaparent;
  width:24px;
  height:24px;
}

.password__container input {
  width: 90%;
  background-color:transaparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.otp-input {
  border: 1px solid transparent;
  width: 76px;
  height: 76px;
  font-size: 24px;
  color: #33B469;
  font-weight: 800;
  background-color: #F5F5F5;
  font-family: '"Urbanist"', "sans-serif";

  &:focus {
    border: 1px solid #33B469;
    ;
  }

}

.line {
  @apply border-b-[1px] w-full;
}

.sidebar--container {
  // @apply flex absolute z-40 w-340 h-full bg-white;
  @apply flex absolute z-40 w-270 h-full bg-white;
  border-right: 1px solid #E1E1E1;

}

.sidebar__link {
  // @apply text-dark200 text-sm font-inter flex items-center px-24 py-16 cursor-pointer transition-all;
  @apply text-dark200 text-[13px] font-inter flex items-center px-8 py-16 cursor-pointer transition-all;

  &:hover,
  &.active {
    @apply bg-[#33B469] bg-opacity-10 text-primary font-bold;
  }

  span {
    @apply ml-8;
  }

  &.disabled {
    @apply opacity-50  pointer-events-none;
  }
}

.content {
  width: 100%;
  // padding-left: 340px;
  padding-left: 270px;
}

.dashboard--header {
  @apply fixed w-full bg-white py-12 px-27 flex flex-nowrap justify-between z-50;
  border-bottom: 1px solid #E1E1E1;
}

.dashboard--content {
  height: calc(100vh - 1rem);
  // padding-top: 100px;
  padding-top:4rem;
}

.dashboard--header--pills {
  @apply w-32 h-32 rounded-full bg-grey500 border-1 flex items-center justify-center border-grey100 mr-10;
}

.upload__area {
  @apply w-full h-270 mb-40 rounded-15 relative;
  border: 2px dashed #9E9E9E;


  input[type="file"] {
    @apply w-full h-full cursor-pointer;
    opacity: 0;
  }

  &.disabled {
    @apply pointer-events-none opacity-50;
  }
}

.transparent__input {
  @apply bg-transparent border-0 outline-none text-dark200 text-sm font-normal w-full p-20 focus:ring-0 block placeholder-black placeholder-opacity-50;
}

.mx-flush {
  @apply py-15 px-36;
}

.search__content {
  @apply flex items-center bg-grey500 py-16 px-20 mr-16 w-180;

  input {
    @apply bg-transparent border-0 outline-none text-dark200 text-sm font-normal w-full p-2 focus:ring-0 block placeholder-black placeholder-opacity-50;
  }
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.loan__card {
  @apply bg-white p-8 flex flex-col justify-between rounded-3 h-165;


  h5 {
    @apply font-medium font-inter text-sm text-black basis-[60%] flex-shrink-0;
  }

  h2 {
    @apply font-bold font-inter text-[1.8rem] text-black;
  }


  p {
    @apply flex items-center text-dark100 font-inter text-12;
  }

  select {
    @apply bg-transparent text-grey600;

    &:hover {
      @apply bg-grey100 cursor-pointer;
    }
  }
}


.package__card {
  @apply bg-white basis-[48%] p-14 flex flex-col rounded-3 min-h-165;
  border:1px solid #F5F5F5;

  h5 {
    @apply  font-inter text-base  mb-16;
  }


  p {
    @apply text-dark100 font-inter text-12 ml-8;
  }
}


.sweep__settings {
  @apply bg-white p-14 flex flex-col rounded-3 h-177;


  h5 {
    @apply font-medium font-inter text-sm text-black;
  }

  h6 {
    @apply font-medium font-inter text-sm text-black;
  }


  p {
    @apply font-normal text-grey850 font-inter text-12;
  }

  
}

.sweep__card {
  @apply  flex flex-col rounded-3 h-177 bg-green-600 text-white; 
}

.sweep-container {

  .loan__card {
    @apply h-177;
  }
}

.graph__card {
  @apply bg-white min-h-272 p-14 flex flex-col rounded-3;

  h5 {
    @apply font-medium font-inter text-sm text-black basis-[60%] flex-shrink-0;
  }


  h4 {
    @apply font-bold text-2xl font-inter text-grey600;
  }

}

.table__card {
  @apply bg-white h-440 p-24 flex flex-col rounded-3;

  h5 {
    @apply font-medium font-inter text-sm text-black basis-[60%] flex-shrink-0;
  }
}

.min__table__card {
  @apply bg-white min-h-440 p-24 flex flex-col rounded-3;

  h5 {
    @apply font-medium font-inter text-sm text-black basis-[60%] flex-shrink-0;
  }
}


table.table {
  white-space: nowrap !important;
  caption-side: bottom;
  border-collapse: collapse;
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  width:100%;

  thead {
    vertical-align: bottom;
    background-color: #EAECF0;
    @apply py-16  block;


    tr {
      border-bottom: 1px solid #EAECF0;


      th {
        text-align: left;
        @apply font-medium font-inter text-12 text-grey800;

        &:not(:first-child) {
          padding-left: 1rem;
        }
      }
    }
  }

  tbody {
    border-width: 0;
    @apply py-16  block;



    tr {
      background-color: white;

      &.head {
        background-color: #F9FAFB;
        border-bottom: 1px solid #EAECF0;
        
        h6 {
          @apply font-medium font-inter text-12 text-grey800;
        }

        td {
          &:first-child {
            @apply py-16; 
          }
        }
      }


      td {
        border-bottom: 1px solid #EAECF0;
        @apply py-16;
    
        &:first-child {
          @apply px-24; 
        }

        &:not(:first-child) {
          padding-left: 1rem;
        }

        h5 {
          @apply font-normal font-inter text-sm  text-dark400;
        }

        h5 {
          @apply font-medium font-inter text-sm text-grey800;
        }
      }
      
    }
  }
}

.dashboard__table__header {
  background-color: #EAECF0;
  height: 50px;
  border-bottom: 1px solid #EAECF0;
}

.dashboard__table__body {
  background-color: #F9FAFB;
  border-bottom: 1px solid #EAECF0;

  tr {
    height: 50px;

    h6 {
      @apply font-medium font-inter text-sm text-grey800;
    }
  }


}

.credit__table {
  background-color: #D1D9E1;
}


.credit__table__header {
  background-color: #EAECF0;
  height: 50px;
  border: 1px solid #FAFAFA;
}

.credit__table__body {
  background-color: white;
  border-bottom: 1px solid #D1D9E1;

  tr {
    height: 50px;
    h6 {
      @apply font-medium font-inter text-sm text-grey800;
    }
  }


}





.dashboard__accounts__container {
  @apply border border-grey900 rounded-6;
}


.react-tel-input .form-control {
  width:100% !important;

}

.react-tel-input {
 
  input {
    background-color: #EDEDED !important;
    border:none !important;
    border-radius: 0 !important;
    height:auto !important;
    padding: 20px Im !important;
    padding-left: 48px !important;
  }
}

.credit-tab {
  @apply px-32;

  button {
    @apply font-inter text-[#667085] text-base font-medium mr-8 py-16 transition-all;

    &.active {
       color: #6941C6;
       border-bottom: 2px solid #6941C6;
    }
  } 
}

.half-circle {
  width: 200px;
  height: 100px;
  border-radius: 100px 100px 0 0;
  background-color: white;
}

.badge-container {
  @apply flex items-center justify-center px-8 py-4 rounded-16 text-12;

  p {
    @apply text-center font-medium font-inter;
  }

  span {
    @apply w-6 h-6 rounded-full mr-8;
  }

  &.success {
    background-color: #ECFDF3;

    p {
      color: #027A48;
    }

    span {
      @apply bg-primary;
    }
  }

  &.pending {
    @apply bg-orange-200 text-orange-600;


    span {
      @apply bg-orange-600;
    }
  }

  &.danger {
    @apply bg-red-200 text-red-600;

    span {
      @apply bg-red-600;
    }
  }
}

.textarea {
  resize: none;
}

textarea {
  resize: none;
}

.carousel-container {
  height: 100%;

  ul {
   height: 100%;
  }
}

ul.react-multi-carousel-dot-list {
  height:180px;
  display: flex;
  justify-content: flex-start;
  padding-left: 58px;

  button {
    height: 5px;
    width: 100px;
    background-color: white;
    border-radius: 5px;
    margin-right: 10px;

    &.active{
    background-color: #33B469;

    }
  }
}





// @font-face {
//   font-display: swap;
//   font-style: normal;
//   font-family:"Graphik";
//   src: url('../assets/Graphik/GraphikLight.otf') format("otf");
//   font-weight: 300;
// }

// @font-face {
//   font-display: swap;
//   font-style: normal;
//   font-family: "Graphik";
//   src: url('../assets/Graphik/GraphikRegular.otf') format("otf");
//   font-weight: 400;
// }

// @font-face {
//   font-display: swap;
//   font-style: normal;
//   font-family: "Graphik";
//   src: url('../assets/Graphik/GraphikMedium.otf') format("otf");
//   font-weight: 500;
// }

// @font-face {
//   font-display: swap;
//   font-style: normal;
//   font-family:"Graphik";
//   src: url('../assets/Graphik/GraphikSemibold.otf') format("otf");
//   font-weight: 600;
// }

// @font-face {
//   font-display: swap;
//   font-style: normal;
//   font-family: "GraphikBold";
//   src: url('../dddassets/Graphik/GraphikBold.otf') format("otf");
//   font-weight: 700;
// }


.half-circle {
  width: 200px;
  height: 100px;
  border-radius: 100px 100px 0 0;
  background-color: white;
  border:20px solid green;

  border-left-color: #0066FF;
  border-top-color: #0066FF;
  border-right-color: #EFEFFF;
  border-bottom-color: transparent;
  border-bottom-width: 0;

}

.f {
  border-radius: 100px 100px 0 0;

}


